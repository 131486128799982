<template>
  <span class="icon-defined" :class="'icon-'+_type">
    <!-- <slot v-if="_type=='down'">
      <svg t="1712121975671" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5125" width="50" height="50">
        <path d="M490.155 696.835c5.703 5.703 13.229 8.617 20.753 8.496 7.524 0 15.050-2.791 20.875-8.496l179.739-179.739c11.408-11.408 11.408-29.855 0-41.142-5.703-5.703-13.107-8.496-20.631-8.496s-14.928 2.791-20.631 8.496l-130.222 130.222v-483.389c0-16.142-12.986-29.127-29.127-29.127s-29.127 12.986-29.127 29.127v483.267l-130.222-130.222c-5.703-5.703-13.107-8.496-20.631-8.496s-14.928 2.791-20.631 8.496c-11.408 11.408-11.408 29.855 0 41.142l179.86 179.86z" fill="" p-id="5126"></path>
        <path d="M883.007 497.194c-16.142 0-29.127 12.986-29.127 29.127 0 189.084-153.767 342.85-342.85 342.85s-342.85-153.767-342.85-342.85c0-16.142-12.986-29.127-29.127-29.127s-29.127 12.986-29.127 29.127c0 54.128 10.559 106.678 31.554 156.194 20.146 47.818 49.152 90.658 85.925 127.553 36.773 36.773 79.735 65.778 127.553 85.925 49.516 20.875 102.066 31.554 156.194 31.554 54.128 0 106.678-10.559 156.194-31.554 47.818-20.146 90.658-49.152 127.553-85.925 36.773-36.773 65.778-79.735 85.925-127.553 20.875-49.516 31.554-102.066 31.554-156.194-0.242-16.020-13.349-29.127-29.37-29.127z" fill="" p-id="5127"></path>
      </svg>
    </slot> -->
    <!-- <slot v-if="_type=='down'">
      <svg t="1712122674228" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5276" :width="_size" :height="_size">
        <path d="M511.97 16c26.51 0 48 21.49 48 48l-0.02 633.57 234.89-234.84c18.74-18.75 49.14-18.75 67.88 0 18.75 18.74 18.75 49.14 0 67.88L625.13 768.2c-28.46 28.46-64.97 43.96-102.2 46.49-3.5 0.88-7.18 1.31-10.96 1.31-3.8 0-7.49-0.44-11.03-1.27-37.17-2.6-73.64-18.1-102.08-46.53L161.28 530.61c-18.75-18.75-18.75-49.14 0-67.88 17.56-17.56 45.35-18.66 64.2-3.29l3.66 3.31 234.85 234.88L463.97 64c0-26.51 21.49-48 48-48z m240 896c26.51 0 48 21.49 48 48s-21.49 48-48 48h-480c-26.51 0-48-21.49-48-48s21.49-48 48-48h480z" :fill="_color" p-id="5277"></path>
      </svg>
    </slot> -->
    <!-- <slot v-if="_type=='share'">
      <svg t="1712556771614" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1124" :width="_size" :height="_size">
        <path d="M820.81 7.93l4.37 2.24 140.53 81.14c51.66 29.82 70.57 94.58 44.12 147.2l-3.13 5.8-81.14 140.53c-13.25 22.96-42.61 30.82-65.57 17.57-21.52-12.43-29.78-39-19.81-61.2l2.24-4.37 62.02-107.42c-171.93 29.36-285.87 127.68-347.28 298.8-8.95 24.95-36.44 37.92-61.39 28.96-24.95-8.95-37.92-36.44-28.97-61.39 69.71-194.25 202-313.8 392.13-355.27l-81.76-47.21c-22.96-13.25-30.82-42.61-17.57-65.57 12.44-21.53 39.02-29.78 61.21-19.81zM512 0c26.51 0 48 21.49 48 48s-21.49 48-48 48C282.25 96 96 282.25 96 512s186.25 416 416 416 416-186.25 416-416c0-26.51 21.49-48 48-48s48 21.49 48 48c0 282.77-229.23 512-512 512S0 794.77 0 512 229.23 0 512 0z" :fill="_color" p-id="1125"></path>
      </svg>
    </slot> -->
    <!-- <slot v-if="_type=='img'">
      <svg t="1712558376196" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1278" :width="_size" :height="_size">
        <path d="M320 256c35.35 0 64 28.65 64 64s-28.65 64-64 64-64-28.65-64-64 28.65-64 64-64z m512-96H192c-35.35 0-64 28.65-64 64v410.66l106.98-110.54c52.83-54.6 140.99-57.06 196.93-5.49l4.03 3.87 3.87 4.02 32.92 35.73 110.41-129.32c49.25-57.69 137.09-65.49 196.2-17.41 9.28 7.55 17.5 16.23 24.47 25.83l4.99 7.37L896 587.07V224c0-35.35-28.65-64-64-64zM660.52 490.37l-2.94 3.11-146.21 171.26c-17.84 20.9-49.7 22.64-69.82 4.73l-3.42-3.36-70.36-76.31-2.4-2.4c-15.96-14.71-40.54-14.97-56.8-1.26l-3.14 2.93L128 772.42V800c0 35.35 28.65 64 64 64h640c35.35 0 64-28.65 64-64v-32.48L726.25 498.29a41.7 41.7 0 0 0-8.97-10.1c-16.86-13.72-41.41-12.49-56.76 2.18zM832 64c86.04 0 156.22 67.91 159.85 153.06L992 224v576c0 86.04-67.91 156.22-153.06 159.85L832 960H192c-86.04 0-156.22-67.91-159.85-153.06L32 800V224c0-86.04 67.91-156.22 153.06-159.85L192 64h640z" :fill="_color" p-id="1279"></path>
      </svg>
    </slot> -->
    <!-- <slot v-if="_type=='look'">
      <svg t="1712123674438" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="934" :width="_size" :height="_size">
        <path d="M512 400c-61.86 0-112 50.14-112 112s50.14 112 112 112 112-50.14 112-112-50.14-112-112-112z m0-96c114.88 0 208 93.12 208 208s-93.12 208-208 208-208-93.12-208-208 93.12-208 208-208z m0-112c-137.52 0-267.07 85.35-389.1 264.91l-8.12 12.11-8.23 12.58c-12.04 18.65-12.06 42.13-0.16 60.56C233.4 738.87 368.38 832 512 832c137.52 0 267.07-85.35 389.1-264.91l8.12-12.11 8.23-12.58c12.04-18.65 12.06-42.13 0.16-60.56C790.6 285.13 655.62 192 512 192z m0-96c181.89 0 344.13 113.63 486.26 333.77 30.95 47.94 32.3 108.79 3.9 158.06l-4.06 6.65-8.53 13.05C849.57 818.9 690.17 928 512 928c-181.89 0-344.13-113.63-486.26-333.77-30.95-47.94-32.3-108.79-3.9-158.06l4.06-6.65 8.53-13.05C174.43 205.1 333.83 96 512 96z" :fill="_color" p-id="935"></path>
      </svg>
    </slot> -->
    <!-- <slot v-if="_type=='close'">
      <span :style="closeStyle">×</span>
    </slot>
    <slot v-else></slot> -->
    <svg :t="icon[_type].t||''" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" :p-id="icon[_type].svgId||''" :width="_size" :height="_size">
      <slot v-if="icon[_type].pathList">
        <path v-for="(item,index) in icon[_type].pathList" :d="item.path" :fill="_color" :p-id="item.pathId||''" :key="_type+index"></path>
      </slot>
      <path v-else :d="icon[_type].path" :fill="_color" :p-id="icon[_type].pathId||''"></path>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    _type: {
      type: String,
      required: true
    },
    _size: {
      type: [String, Number],
      default: '16'
    },
    _color: {
      type: String,
      default: '#1A1A1A'
    },
  },
  data() {
    return {
      icon: {
        'close': { t:'1717121824521', svgId:'1125', pathId:'1126', path:'M865.71 90.76c18.85-15.38 46.66-14.28 64.24 3.29 17.57 17.57 18.67 45.39 3.29 64.24l-3.29 3.65L579.87 512l350.07 350.06c18.74 18.75 18.74 49.14 0 67.88-17.57 17.57-45.39 18.67-64.24 3.29l-3.65-3.29L512 579.87 161.94 929.94l-3.65 3.29c-18.85 15.38-46.66 14.28-64.24-3.29-17.57-17.57-18.67-45.39-3.29-64.24l3.29-3.65L444.13 512 94.06 161.94c-18.74-18.75-18.74-49.14 0-67.88 17.57-17.57 45.39-18.67 64.24-3.29l3.65 3.29L512 444.13 862.06 94.06l3.65-3.3z' },
        'down': { t:'1712122674228', svgId:'5276', pathId:'5277', path:'M511.97 16c26.51 0 48 21.49 48 48l-0.02 633.57 234.89-234.84c18.74-18.75 49.14-18.75 67.88 0 18.75 18.74 18.75 49.14 0 67.88L625.13 768.2c-28.46 28.46-64.97 43.96-102.2 46.49-3.5 0.88-7.18 1.31-10.96 1.31-3.8 0-7.49-0.44-11.03-1.27-37.17-2.6-73.64-18.1-102.08-46.53L161.28 530.61c-18.75-18.75-18.75-49.14 0-67.88 17.56-17.56 45.35-18.66 64.2-3.29l3.66 3.31 234.85 234.88L463.97 64c0-26.51 21.49-48 48-48z m240 896c26.51 0 48 21.49 48 48s-21.49 48-48 48h-480c-26.51 0-48-21.49-48-48s21.49-48 48-48h480z' },
        // 'down': { t:'1717121682295', svgId:'3087', pathId:'3088', path:'M515.2 96.128c229.664 0 415.872 186.176 415.872 415.872 0 229.696-186.208 415.872-415.872 415.872-229.696 0-415.904-186.176-415.904-415.872 0-229.696 186.208-415.872 415.904-415.872z m0 64C320.864 160.128 163.296 317.664 163.296 512S320.864 863.872 515.2 863.872c194.336 0 351.872-157.536 351.872-351.872S709.536 160.128 515.2 160.128z m10.624 148a32 32 0 0 1 31.808 28.256l0.192 3.744v256.576l112-112.16a32 32 0 0 1 47.936 42.208l-2.656 3.008-160.448 160.768a32.032 32.032 0 0 1-23.296 15.936 32 32 0 0 1-32.544-14.368l-161.536-161.28a32 32 0 0 1 42.208-47.968l3.008 2.656 111.296 111.168 0.032-256.544a32 32 0 0 1 32-32z' },
        'share': { t:'1712556771614', svgId:'1124', pathId:'1125', path:'M820.81 7.93l4.37 2.24 140.53 81.14c51.66 29.82 70.57 94.58 44.12 147.2l-3.13 5.8-81.14 140.53c-13.25 22.96-42.61 30.82-65.57 17.57-21.52-12.43-29.78-39-19.81-61.2l2.24-4.37 62.02-107.42c-171.93 29.36-285.87 127.68-347.28 298.8-8.95 24.95-36.44 37.92-61.39 28.96-24.95-8.95-37.92-36.44-28.97-61.39 69.71-194.25 202-313.8 392.13-355.27l-81.76-47.21c-22.96-13.25-30.82-42.61-17.57-65.57 12.44-21.53 39.02-29.78 61.21-19.81zM512 0c26.51 0 48 21.49 48 48s-21.49 48-48 48C282.25 96 96 282.25 96 512s186.25 416 416 416 416-186.25 416-416c0-26.51 21.49-48 48-48s48 21.49 48 48c0 282.77-229.23 512-512 512S0 794.77 0 512 229.23 0 512 0z' },
        'img': { t:'1712558376196', svgId:'1278', pathId:'1279', path:'M320 256c35.35 0 64 28.65 64 64s-28.65 64-64 64-64-28.65-64-64 28.65-64 64-64z m512-96H192c-35.35 0-64 28.65-64 64v410.66l106.98-110.54c52.83-54.6 140.99-57.06 196.93-5.49l4.03 3.87 3.87 4.02 32.92 35.73 110.41-129.32c49.25-57.69 137.09-65.49 196.2-17.41 9.28 7.55 17.5 16.23 24.47 25.83l4.99 7.37L896 587.07V224c0-35.35-28.65-64-64-64zM660.52 490.37l-2.94 3.11-146.21 171.26c-17.84 20.9-49.7 22.64-69.82 4.73l-3.42-3.36-70.36-76.31-2.4-2.4c-15.96-14.71-40.54-14.97-56.8-1.26l-3.14 2.93L128 772.42V800c0 35.35 28.65 64 64 64h640c35.35 0 64-28.65 64-64v-32.48L726.25 498.29a41.7 41.7 0 0 0-8.97-10.1c-16.86-13.72-41.41-12.49-56.76 2.18zM832 64c86.04 0 156.22 67.91 159.85 153.06L992 224v576c0 86.04-67.91 156.22-153.06 159.85L832 960H192c-86.04 0-156.22-67.91-159.85-153.06L32 800V224c0-86.04 67.91-156.22 153.06-159.85L192 64h640z' },
        'look': { t:'1712123674438', svgId:'934', pathId:'935', path:'M512 400c-61.86 0-112 50.14-112 112s50.14 112 112 112 112-50.14 112-112-50.14-112-112-112z m0-96c114.88 0 208 93.12 208 208s-93.12 208-208 208-208-93.12-208-208 93.12-208 208-208z m0-112c-137.52 0-267.07 85.35-389.1 264.91l-8.12 12.11-8.23 12.58c-12.04 18.65-12.06 42.13-0.16 60.56C233.4 738.87 368.38 832 512 832c137.52 0 267.07-85.35 389.1-264.91l8.12-12.11 8.23-12.58c12.04-18.65 12.06-42.13 0.16-60.56C790.6 285.13 655.62 192 512 192z m0-96c181.89 0 344.13 113.63 486.26 333.77 30.95 47.94 32.3 108.79 3.9 158.06l-4.06 6.65-8.53 13.05C849.57 818.9 690.17 928 512 928c-181.89 0-344.13-113.63-486.26-333.77-30.95-47.94-32.3-108.79-3.9-158.06l4.06-6.65 8.53-13.05C174.43 205.1 333.83 96 512 96z', },
        'image': { t:'1717124023412', svgId:'1125', pathId:'1126', path:'M320 256c35.35 0 64 28.65 64 64s-28.65 64-64 64-64-28.65-64-64 28.65-64 64-64z m512-96H192c-35.35 0-64 28.65-64 64v410.66l106.98-110.54c52.83-54.6 140.99-57.06 196.93-5.49l4.03 3.87 3.87 4.02 32.92 35.73 110.41-129.32c49.25-57.69 137.09-65.49 196.2-17.41 9.28 7.55 17.5 16.23 24.47 25.83l4.99 7.37L896 587.07V224c0-35.35-28.65-64-64-64zM660.52 490.37l-2.94 3.11-146.21 171.26c-17.84 20.9-49.7 22.64-69.82 4.73l-3.42-3.36-70.36-76.31-2.4-2.4c-15.96-14.71-40.54-14.97-56.8-1.26l-3.14 2.93L128 772.42V800c0 35.35 28.65 64 64 64h640c35.35 0 64-28.65 64-64v-32.48L726.25 498.29a41.7 41.7 0 0 0-8.97-10.1c-16.86-13.72-41.41-12.49-56.76 2.18zM832 64c86.04 0 156.22 67.91 159.85 153.06L992 224v576c0 86.04-67.91 156.22-153.06 159.85L832 960H192c-86.04 0-156.22-67.91-159.85-153.06L32 800V224c0-86.04 67.91-156.22 153.06-159.85L192 64h640z' },
        'search': { t:'1717121490232', svgId:'1125', pathId:'1126', path:'M496 96C275.09 96 96 275.08 96 496s179.09 400 400 400 400-179.08 400-400S716.91 96 496 96z m0-96c273.93 0 496 222.07 496 496 0 119.69-42.4 229.48-112.99 315.17l130.93 130.89c18.75 18.75 18.75 49.14 0 67.88-17.57 17.57-45.38 18.67-64.24 3.29l-3.65-3.29-130.88-130.93C725.48 949.6 615.69 992 496 992 222.07 992 0 769.93 0 496S222.07 0 496 0z' },
        // 'search': { t:'1717122523389', svgId:'5999', pathId:'6001', path:'M999.424 950.272L757.76 708.608c-1.024-1.024-3.072-3.072-5.12-4.096l-7.168-5.12 5.12-6.144c61.44-74.752 95.232-168.96 95.232-265.216C845.824 196.608 657.408 9.216 427.008 9.216 195.584 8.192 8.192 196.608 8.192 427.008s188.416 418.816 418.816 418.816h1.024c96.256 0 190.464-33.792 265.216-95.232l6.144-5.12 5.12 7.168c1.024 2.048 3.072 3.072 4.096 5.12l241.664 241.664c6.144 6.144 15.36 10.24 24.576 10.24 9.216 0 18.432-4.096 24.576-10.24 12.288-13.312 13.312-35.84 0-49.152z m-572.416-174.08c-192.512 0-349.184-156.672-349.184-349.184S234.496 77.824 427.008 77.824s349.184 156.672 349.184 349.184-156.672 349.184-349.184 349.184z' }, // M427.008 153.6C276.48 153.6 153.6 276.48 153.6 427.008c0 19.456 16.384 35.84 35.84 35.84s35.84-16.384 35.84-35.84C225.28 316.416 315.392 225.28 427.008 225.28c19.456 0 35.84-16.384 35.84-35.84S446.464 153.6 427.008 153.6z
        'blowup': { t:'1717123953763', svgId:'59183', pathId:'59184', path:'M490.382222 895.374222c221.980444 0 403.171556-181.134222 404.992-404.650666A404.821333 404.821333 0 0 0 490.382222 85.504 404.764444 404.764444 0 0 0 85.504 490.382222a404.764444 404.764444 0 0 0 404.935111 404.935111zM462.449778 447.146667H277.390222a42.723556 42.723556 0 0 0 0 85.504h185.002667v185.002666a42.723556 42.723556 0 1 0 85.447111 0V532.707556h185.002667a42.723556 42.723556 0 0 0 0-85.504H547.84V262.257778a42.723556 42.723556 0 1 0-85.447111 0V447.146667z m28.046222 533.617777A490.211556 490.211556 0 0 1 0 490.439111 490.211556 490.211556 0 0 1 490.382222 0c271.075556 0 490.439111 219.477333 490.439111 491.064889-2.104889 270.677333-221.354667 489.813333-490.382222 489.813333z' },
        'success': { t:'', svgId:'', pathId:'', path:'' },
        'error': { t:'', svgId:'', pathId:'', path:'' },
        'warning': { t:'', svgId:'', pathId:'', path:'' },
        'info': { t:'1720165947870', svgId:'19793', pathId:'19794', path:'M512 106.016z m-106.016 0q0 28.992 14.016 52.992t39.008 38.496T512 212t52.992-14.496 39.008-38.496 14.016-53.024q-1.024-44.992-31.04-74.976T512 0q-44.992 0.992-75.008 31.008t-31.008 75.008zM512 317.952q-46.016 1.024-75.52 30.528t-30.496 75.488v494.016q1.024 45.984 30.528 75.488t75.488 29.504 75.52-29.504 30.496-75.52V424q-1.024-46.016-30.528-75.52T512 318.016z' },
        'order': { /*t:'1740973581249', svgId:'5386',*/ pathList:[
          {/*pathId:'5387',*/ path:'M703.904 282.672H301.248a36.944 36.944 0 1 1 0-73.888h402.656a36.944 36.944 0 0 1 0 73.888z'},
          {/*pathId:'5388',*/ path:'M703.904 466.736H301.248a36.944 36.944 0 1 1 0-73.888h402.656a36.944 36.944 0 0 1 0 73.888z'},
          {/*pathId:'5389',*/ path:'M376.096 992H99.952A35.84 35.84 0 0 1 64 955.584V68.416A35.84 35.84 0 0 1 99.952 32h824.096A35.84 35.84 0 0 1 960 68.416v536.816a14.4 14.4 0 0 1-0.72 5.824 35.776 35.776 0 0 1-10.064 32l-322.16 320.48a35.008 35.008 0 0 1-38.832 7.28 36.336 36.336 0 0 1-22.288-33.504V613.248a36.64 36.64 0 0 1 35.2-36.416l286.928-7.28V104.832H135.904v814.4h240.176a36.416 36.416 0 0 1 0 72.832z m262.4-343.792v201.76L847.04 643.104z'}]
        },
      },
    }
  },
  computed: {
    closeStyle() {
      // console.log(this._color);
      let style = `line-height: 1; font-weight:300; font-family: Avenir, Helvetica, Arial, sans-serif; text-align: center; display:block; user-select:none; text-shadow: 0 1px 2px #fff;`;
      style += `color:${this._color};`;
      style += `font-size:${this._size.toString().split('px')[0]}px;`;
      style += `width:${this._size.toString().split('px')[0]}px;`;
      return style;
    },
  },
  // watch: {
  //   _color: {
  //     handler(_new, _old) {
  //       // console.log(_new, _old);
  //     },
  //     immediate: true,
  //   },
  // }
}
</script>

<style lang="scss">
.icon-defined {
  // font-family: SourceHanSansCN-Normal, "\5FAE\8F6F\96C5\9ED1", Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  &.icon-image, &.icon-down {
    transform: scale(1.12);
  }
}
</style>